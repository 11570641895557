<template>
  <en-drawer :model-value="modelValue" title="编辑服务类型" @close="$emit('update:model-value', false)">
    <en-form :model="form.data" :loading="form.loading">
      <en-form-item label="提前多少天通知" prop="categoryKey">
        <en-input-number v-model="form.data.days"></en-input-number>
      </en-form-item>
      <en-form-item label="状态">
        <en-radio-group :model-value="form.data.status?.code" @change="form.status.change">
          <en-radio label="A">启用</en-radio>
          <en-radio label="D">禁用</en-radio>
        </en-radio-group>
      </en-form-item>
      <en-form-item label="提前多少天微信通知" prop="customer.id">
        <en-input-number v-model="form.data.wechatDays"></en-input-number>
      </en-form-item>
      <en-form-item label="状态">
        <en-radio-group :model-value="form.data.wechatStatus?.code" @change="form.wechatStatus.change">
          <en-radio label="A">启用</en-radio>
          <en-radio label="D">禁用</en-radio>
        </en-radio-group>
      </en-form-item>
    </en-form>

    <template #footer>
      <en-button @click="footer.cancel.click">取消</en-button>
      <button-ajax :method="footer.confirm.click">确定</button-ajax>
    </template>
  </en-drawer>
</template>

<script lang="ts">
export default factory({
  props: {
    modelValue: Boolean,
    data: Object as PropType<EnocloudBusinessOpportunityDefinitions['BusinessOpportunityCategoryDto']>
  },

  emits: {
    'update:model-value': (value: boolean) => typeof value === 'boolean',
    confirm: () => true
  },

  watch: {
    modelValue: {
      async handler() {
        this.form.init()
        if (this.modelValue) {
          this.form.data.id = this.data?.id
          if (this.form.data.id) this.form.get()
        }
      }
    }
  },

  config: {
    children: {
      form: {
        ajax: {
          get: {
            action: 'GET /enocloud/business/opportunity/category/:categoryId',
            data: 'object',
            init: true,
            loading: true,
            params(params) {
              params.paths = [this.form.data.id]
            }
          },
          update: {
            action: 'PUT /enocloud/business/opportunity/category',
            loading: true,
            validate: true,
            params(params) {
              params.payload = this.form.data
            }
          }
        },
        children: {
          status: {
            change(value: string) {
              this.form.data.status ??= { code: '', type: '', message: '', description: '' }
              this.form.data.status.code = value
            }
          },
          wechatStatus: {
            change(value: string) {
              this.form.data.wechatStatus ??= { code: '', type: '', message: '', description: '' }
              this.form.data.wechatStatus.code = value
            }
          }
        }
      },
      footer: {
        cancel: {
          click() {
            this.emit('update:model-value', false)
          }
        },
        confirm: {
          async click() {
            await this.form.update()
            this.emit('update:model-value', false)
            this.emit('confirm')
          }
        }
      }
    }
  }
})
</script>
